<template>
  <FinalForm
    :submit="onSubmit"
    ref="form"
    :initialValues="initialValues"
    @change.self="handleFormChange"
  >
    <template v-slot="props">
      <form @submit="props.handleSubmit" class="min-h-full">
        <div class="form-row bg-gray-100 p-4 mb-6">
          <TextField name="name" label="partner name" :validate="required"/>
        </div>
        <div v-if="!formValues.fields || !formValues.fields.length">
          <button type="button" class="ext-blue-500 pl-4 cursor-pointer flex items-center" @click="addField">
            <icon name="plus" class="h-5 w-5 mx-auto mr-2 text-blue-500 cursor-pointer"/>
            add field
          </button>
        </div>
        <div v-for="(field, index) in formValues.fields" :key="index" class="flex mb-8">
          <div class="w-5/12 pr-4">
            <div class="form-col">
              <TextField :name="`fields[${index}].fieldName`" label="parameter" :validate="required"/>
            </div>
          </div>
          <div class="w-6/12 px-4">
            <div class="form-col">
              <TextField :name="`fields[${index}].fieldDescription`" label="helper text" :validate="required"/>
            </div>
          </div>
          <div class="w-1/12 px-4 flex flex-col justify-center">
            <button type="button" @click="removeField(index)">
              <icon
                name="closeCircle"
                class="h-5 w-5 text-red-600 mx-auto cursor-pointer"
              />
            </button>
            <button type="button" @click="addField">
              <icon
                name="plus"
                class="h-5 w-5 mx-auto mt-2 text-blue-500 cursor-pointer"
              />
            </button>
          </div>
        </div>
        <ModalFooter :footer="footer" :secondary="close"/>
      </form>
    </template>
  </FinalForm>
</template>

<script>
  import {FinalForm} from 'vue-final-form';
  import TextField from '@/components/form/TextField';
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import InitializeFormMixin from "@/components/form/InitializeFormMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Icon from "@/components/ui/Icon";
  import ModalFooter from "@/components/ui/modals/ModalFooter";

  export default {
    name: "IntegrationForm",
    components: {
      ModalFooter,
      TextField,
      FinalForm,
      Icon
    },
    mixins: [
      ValidatorMixin,
      InitializeFormMixin,
      NotifyMixin
    ],
    data: function () {
      return {
        formValues: {},
        footer: {
          primaryButton: 'save partner',
          secondaryButton: 'cancel',
        }
      }
    },
    props: {
      onSubmit: {
        type: Function,
        required: true,
      },
      initialValues: {
        type: Object,
        default: () => ({fields: [{fieldName: '', fieldDescription: ''}]})
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      edit: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    emits: ['close'],
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },
      addField() {
        const {form: {formState: {values: {fields}}, finalForm: {change}}} = this.$refs;
        change('fields', [...fields, {fieldName: '', fieldDescription: ''}]);
      },
      removeField(index) {
        const {form: {formState: {values: {fields}}, finalForm: {change}}} = this.$refs;
        change('fields', fields.filter((f, i) => i !== index));
      },
      close(e) {
        e.preventDefault();
        this.$emit('close');
      }
    }
  }
</script>
