<template>
  <Details
    title="integration partner"
    resource="partners"
    :base-path="basePath"
    data-provider="$partnerDataProvider"
  >
    <template v-slot:default="detailsProps">
      <IntegrationForm
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import IntegrationForm from "@/views/auth/integrations/IntegrationForm";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import Details from "@/components/auth/details/Details";

  export default {
    name: "IntegrationsCreate",
    components: {
      IntegrationForm,
      Details
    },
    mixins: [ModalNavigation],
    computed: {
      basePath: function () {
        return this.$route.name.replace('create', 'index');
      }
    }
  }
</script>
